@import url("https://cdn.jsdelivr.net/npm/@xz/fonts@1/serve/metropolis.min.css");
@import url("https://webfontworld.github.io/naver/MaruBuri.css");
@import url("https://cdn.jsdelivr.net/gh/toss/tossface/dist/tossface.css");
@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/static/pretendard.min.css");

@font-face {
  font-family: "sogang";
  src: url("https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2312-1@1.1/SOGANGUNIVERSITYTTF.woff2")
    format("woff2");
  font-weight: normal;
  font-style: normal;
}

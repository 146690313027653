@import "./fonts.scss";

@import url("https://cdn.jsdelivr.net/npm/reset-css@5.0.1");

* {
  --black: #212427;
  box-sizing: border-box !important;
  line-height: 1.4;
}

body {
  font-family: "Metropolis", "Pretendard", "Tossface", "Sans-serif", sans-serif;
  max-width: 1600px;
  color: var(--black);
  background: #f1f1f1;
  line-height: 1.2;
  letter-spacing: -0.02ch;
}

a {
  text-decoration: none;
  color: var(--black);
}
